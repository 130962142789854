.sidebar-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 92px);
}

.selections {
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 0 0;
}

.available-games {
  flex: 1 1 20rem;
  overflow: auto;
}

.selection {
  margin-bottom: 1rem;
}

.message {
  text-align: center;
}
.game-select-all {
  display: inline-flex;
  width: 90%;
  margin-bottom: 1rem;
}

.games-title {
  margin-right: auto;
  margin-bottom: 0rem;
}

.check-all {
  align-self: center;
}

.game-list {
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.month-header {
  margin-bottom: 0.4rem;

  .month {
    text-transform: uppercase;
    font-weight: bolder;
  }

  .year {
    font-weight: 300;
  }
}

.month-header:hover {
  text-decoration: underline;
  cursor: pointer;
}
