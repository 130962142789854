.outer-container {
  position: relative;
}

.inner-container {
  padding: 2rem;
}

.buttons {
  display: inline-block;
  width: 100%;
  margin: 1rem 0;
}

.button {
  width: 50%;
}

.loading-container {
  z-index: 999;
  position: absolute;
  width: 100%;
  top: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
