.chart-container {
  position: relative;
  height: 50%;
  width: 33%;
  flex-grow: 1;
  canvas {
    margin: 0 auto;
    &.selection {
      position: absolute;
      left: 0px;
      top: 0px;
      pointer-events: none;
    }
  }
}

.full-size-chart {
  height: 100%;
  width: 100%;
}
