.pitch-type {
  text-align: center;
  margin: 0 auto;
}

svg {
  display: block;
  margin: 0 auto;
}

.radio {
  display: block;
  margin: 0 auto;
}
