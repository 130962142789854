.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pitcher {
  margin: 0.5rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.chart-selection {
  margin: 0.5rem;
  padding: 0.4rem;
}

.buttons {
  width: 60%;
}

.button {
  margin: 0.5rem;
}
