.app {
  display: inline-flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
  width: 82%;
}

.sidebar {
  width: 18%;
  overflow-y: auto;
}

.header {
  grid-column: 1/16;
  grid-row: 1/2;
}
.graphs {
  grid-column: 1/16;
  grid-row: 2/14;
}
.buttons {
  grid-column: 1/16;
  grid-row: 14/16;
}

.footer {
  grid-column: 1/16;
  grid-row: 16/16;
}

.item {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
