.container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 1.5rem;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  margin-right: 1rem;
  flex-grow: 2;
}

.handedness-container {
  margin: auto 1rem;
}
