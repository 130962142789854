.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.title {
  margin-bottom: 1.6rem;
  font-size: 3.2rem;
  text-align: center;
  img {
    width: 8rem;
  }
}

.content {
  margin: 0 auto;
  text-align: center;
}
